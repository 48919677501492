body {
    margin: 0px;
    -webkit-font-smoothing: antialiased;
}

body, * {
	font-family: HelveticaNeue-Light, Verdana;
	font-size: 12px;
    box-sizing: border-box;
    outline: none;
}

header {
    background: #f9f9f9;
    height: 60px;
    padding: 0 20px;
    display: grid;
    grid-template-columns: auto auto;
}

header .actions {
    text-align: right;
}

.saved-json-header button.run,
header .actions button.run {
    display: inline-block;
    font-size: 14px;
    text-align: center;
    white-space: nowrap;
    border: 1px solid #999;
    border-radius: 4px;
    background: #999;
    color: #f8f8f8;
    border: 0px;
    height: 30px;
    line-height: 30px;
    margin: 15px 0;
    padding: 0 10px;
    cursor: pointer;
    vertical-align: top;
}

.saved-json-header button.run{
    background: none;
    color: #cecece;
    border: 1px solid #cecece;
}

header .actions button.edit {
    display: inline-block;
    font-size: 14px;
    text-align: center;
    white-space: nowrap;
    border: 1px solid #999;
    border-radius: 4px;
    background: none;
    color: #999;
    height: 30px;
    line-height: 30px;
    margin: 15px 10px 15px 0;
    padding: 0 10px;
    cursor: pointer;
}

main {
    padding: 0 20px 20px 20px;
    min-height: calc(100vh - 120px);
}

footer{
    height: 60px;
    line-height: 60px;
    box-sizing: border-box;
    padding: 0 20px;
    color:#777;
    text-align: right;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.footnotes{
    text-align: left;
}

.contacts{
    text-align: right;
}

footer a{
    color:#777;
}

.logo {
    color: #777;
    font-size: 18px;
    line-height: 60px;
    position: relative;
    padding-left: 40px;
}

.logo:before {
    position: absolute;
    top: 15px;
    left: 0;
    content:'';
    color: #777;
    font-size: 18px;
    background-image: url('./assets/logo.png');
    background-repeat: no-repeat;
    background-size: auto 20px;
    width: 30px;
    height: 30px;
    background-position: center;
    opacity: 0.1;
    border: 3px solid #777;
    box-sizing: border-box;
}