.error{
    color: rgba(253, 74, 74, 0.733);
    font-size: 16px;
    margin-right: 20px;
    vertical-align: top;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    margin: 0;
    position: absolute;
    top: 0; 
    right:80px;
}

.json-editor,
.json-input {
    height: 100%;
}

.json-input{
    background: #cecece;
    width: 100vw;
    margin-left: -20px;
    padding:40px 70px;
    position: relative;
}

.saved-json-tab{
    display: inline-block;
    margin: 20px 10px 0 0; 
    height: 20px;
    line-height: 20px;
    right: 90px;
    top:20px;
}

.saved-json-tab button.show-saved-json{
    -webkit-appearance: none;
    height: 20px;
    background: none;
    border: none;
    background-image: url('../../assets/database-dark.png');
    background-size: 18px auto;
    background-repeat: no-repeat;
    background-position: left center;
    opacity: 0.3;
    margin-left: 5px;
    padding-left: 25px;
    padding-right: 0;
    color: #000;
    font-size: 12px;
    cursor: pointer;
}

textarea#raw-json {
    -webkit-appearance: none;
    width: 100%;
    border: 3px solid #f8f8f8;
    padding: 10px;
    border-radius: 5px;
    font-family: monaco;
    resize: none;
    min-height: 200px;
    background: #fff;
    border: 3px solid transparent;
}

textarea#raw-json:focus{
    outline: none;
    border: 3px solid #cecece;
}

.json-display-grid{
    display: grid;
    grid-template-columns: 120px auto;
}

.json-editor.display ul.saved-json-list > li > div{
    background-position: left center;
}

.json-editor.display ul.saved-json-list > li > button{
    text-align: left;
}

.site-description h1{
    text-align: right;
    font-size: 40px;
}

.site-description h2{
    font-weight: normal;
    font-size: 30px;
    text-align: right;
}

.site-description p{
    text-align: right;
    font-size: 16px;
}

.site-description sup{
    font-size: 10px;
}

