.json-display {
    position: relative;
    text-align: left;
    padding: 40px 20px 20px 20px;
}

.json-display .novr{
	display: none !important;
}

.json-display span {
	white-space : pre-wrap;
}

.json-display .label {
	margin: 0px 0px 0px 30px;
	font-size: 12px;
}

.json-display .label, 
.json-display .key {
	cursor: pointer;
}

.json-display .array, 
.json-display .obj {
	display: block;
	padding: 0px 0px 0px 30px;
}

.json-display span .array, 
.json-display span .obj {
	border-left: 1px dotted #777;
}

.json-display .arrEl,
.json-display .objEl {
	display: block;
    position: relative;
}

.json-display .collapsed .array, 
.json-display .collapsed .obj {
	display: none;
}

.json-display .exp {
	position: absolute;
	left: -10px;
	top: 4px;
	cursor: pointer;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 7px 4px 0 4px;
}

.json-display .collapsed .exp {
	border-width: 4px 0 4px 7px;
}

.json-display .exp {
	border-color: #aaa transparent transparent transparent;
}

.json-display .collapsed .exp {
	border-width: 4px 0 4px 7px;
	border-color: transparent transparent transparent #aaa;
}

.json-display .key {
	display: inline-block;
	min-width: 30px;
}

.json-display .key ~ .label, 
.json-display .arrEl .label {
	margin-left: 0px;
}

.json-display .label.null,
.json-display .label.bool,
.json-display .label.n {
	color : #e12972;
	font-weight: bold;
}

.json-display-toolbar{
    position: absolute;
    right: 0;
    z-index: 9999;
    top: 20px;
}

.json-display-toolbar button.save-json{
    -webkit-appearance: none;
    background: none;
    background-image: url('../../assets/save.png');
    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
    border: none;
    width: 20px;
    height: 20px;
    opacity: 0.5;
    cursor: pointer;
}

.json-display-toolbar button[disabled].save-json{
    background-image: url('../../assets/like.png');
}

.json-display-toolbar input[type="text"] {
    display: none;
    height: 30px;
    line-height: 30px;
    padding: 0 10px 0 5px;
    margin:0;
    vertical-align: middle;
    text-align: left;
    margin-right: -25px;
    margin-top: -10px;
    padding-right: 30px;
}

.json-display-toolbar:hover input[type="text"] {
    display: inline-block;
}

.key.collapsed .obj {
    display: none;
}

.json-display .arr_count{
    display: none;
    color: #aaa;
    margin: 0px 2px;
}

.json-display .collapsed .arr_count{
    display: inline-block;
}