.saved-json-panel{
    position: fixed;
    top: 0;
    right: 0;
    background: #333;
    height: 100vh;
    width: 300px;
    padding: 0px 20px 20px 20px;
    -webkit-transition: right ease-out 0.5s;
    z-index: 99999;
}

.saved-json-header{
    height: 60px;
    text-align: right;
}

.saved-json-close{
    border:none;
    background: none;
    background-image: url('../../assets/close.png');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: right calc(50% + 1px);
    height: 30px;
    width: 20px;
    margin-top: 15px;
    cursor: pointer;
    opacity: 0.6;
    float: left;
}

.saved-json-panel.collapsed{
    right: -100%;
}

ul.saved-json-list{
    list-style: none;
    margin: 0;
    padding: 10px 0;
    text-align: right;
}


ul.saved-json-list > li.saved-json-btn{
    border: none;
    display: block;
    padding:3px 10px 3px 10px;
    margin-bottom: 10px;
}

ul.saved-json-list > li.saved-json-btn.no-data{
    color: #cecece;
    font-size: 14px;
}

ul.saved-json-list > li > button.saved-json-load,
ul.saved-json-list > li > button.saved-json-delete{
    -webkit-appearance: none;
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    vertical-align: middle;
}

ul.saved-json-list > li > button.saved-json-load{

    text-align: center;
    color:#777;
    color: #cecece;
    overflow: hidden;
    height: 15px;
    cursor: pointer;
    
}

ul.saved-json-list > li > button.saved-json-delete{
    height: 100%;
    width: 20px;
    height: 20px;
    color: #000;
    background-image: url('../../assets/close.png');
    background-size: 10px auto;
    background-repeat: no-repeat;
    background-position: right calc(50% + 1px);
    opacity: 0.5;
}
