.json-display, .json-display * {
	font-family: monaco;
	font-weight: 100;
}

.json-display span {
	white-space : pre-wrap;
}

.json-display .label.null,
.json-display .label.bool,
.json-display .label.n {
	color : #e12972;
	font-weight: bold;
}

.json-display .label, .json-display .key {
	cursor: pointer;
}

.json-display .collapsed .array, .json-display .collapsed .obj {
	display: none;
}

.json-display span{
	color: #000;
}

.json-display .key {
	color: #000;
}

.json-display .label {
	color : #0B7500;
}

.json-display .label.n {
	color : #6cd2e7;
}

.json-display .label.null,
.json-display .label.bool {
	color : #1A01CC;
}

.json-display.vertical .label.null,
.json-display.vertical .label.bool,
.json-display.vertical .label.n {
	color : #e12972;
	font-weight: bold;
}



